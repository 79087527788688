<template>

  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState=='Update'">Update Clients #{{ formData.id }}</div>
        <div v-if="formState=='Create'">Create Clients</div>
      </template>

      <EditClients
          v-if="formState=='Update'"
          :key="formKey"
          :data="formData"
          :gridApi="formGridApi"
          :modalFormId="formId"
          @close="closeForm"
      />


      <CreateClients
          v-if="formState=='Create'"
          :key="formKey"
          :gridApi="formGridApi"
          :modalFormId="formId"
          @close="closeForm"
      />

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <AgGridTable
          :key="tableKey"
          :cacheBlockSize="cacheBlockSize"
          :columnDefs="columnDefs"
          :extrasData="extrasData"
          :maxBlocksInCache="maxBlocksInCache"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
          :rowData="rowData"
          :rowModelType="rowModelType"
          :url="url"
          className="ag-theme-alpine"
          domLayout='autoHeight'
          rowSelection="multiple"
          @gridReady="onGridReady"

      >
        <template #header_buttons>
          <div v-if="type !='Clients'" class="btn btn-primary" @click="openCreate"><i
              class="fa fa-plus"></i>
            Nouveau
          </div>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';

import moment from 'moment'


export default {
  name: 'ClientsView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateClients: () => import( "./CreateClients.vue"),
    EditClients: () => import( "./EditClients.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue")
  },
  props: ['type'],
  data() {

    return {
      champsAfficher: [
        //LISTE DES CHAMP à MASQUER
      ],
      formId: "clients",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/clients-Aggrid1',
      table: 'clients',
      requette: 0,
      // columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    columnDefs: function () {
      let columnDefs = [
        {
          field: "id",
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {suppressAndOrCondition: true,},
          hide: true,
          headerName: '#Id',
        },
        {
          field: null,
          headerName: '',
          suppressCellSelection: true,
          width: 40,
          hide: this.isShow("interne"),
          pinned: 'left',
          cellRendererSelector: params => {
            return {
              component: 'AgGridBtnClicked',
              params: {
                clicked: field => {
                  this.showForm('Update', field, params.api)
                },
                render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`

              }
            };
          },

        },


        {
          field: "code",
          sortable: true,
          width: 85,
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          headerName: 'code',
        },


        {
          field: "libelle",
          sortable: true,
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          headerName: 'libelle',
        },
        {
          field: "created_at",
          sortable: true,
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          headerName: 'Créer le',
          valueFormatter: params => {
            let retour = params.value
            try {
              retour = moment(params.value).format('DD/MM/YYYY à HH:mm')
            } catch (e) {

            }
            return retour
          }
        }
      ]
      return columnDefs;

    },
    extrasData: function () {
      let params = {};
      if (this.type == "Clients") {
        params['type'] = {value: '', filterType: 'blank'}
        this.champsAfficher = [
          "interne",

        ]
      } else {

        params['type'] = {values: ['nonimporter'], filterType: 'set'}
        this.champsAfficher = []
      }
      this.tableKey++;
      return {
        baseFilter: params,

      }


    },
  },
  watch: {
    '$route': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
        this.tableKey++
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/clients-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }


  },
  methods: {
    isShow(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      return this.champsAfficher.includes(fieldName); // si le champ existe return prend la valeur *true*
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
      this.gridApi.sizeColumnsToFit();
    },
  }
}
</script>
